import i18next, { i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const LOCALISATION_NAMESPACES = process.env.LOCALISATION_NAMESPACES || [];
class I18Next {
  instance: i18n = i18next;
  isInitialized = false;
  public init(cb?: () => void): void {
    if (!this.isInitialized) {
      this.instance
        .use(initReactI18next)
        .use(LanguageDetector)
        .use(HttpApi)
        .init(
          {
            // lng: translationLng,
            supportedLngs: ['en', 'es-419', 'pt-BR', 'fr', 'de'],
            // debug: true,
            updateMissing: true,
            fallbackLng: ['en'],
            nsSeparator: ':',
            ns: LOCALISATION_NAMESPACES,
            maxParallelReads: 100,
            defaultNS: 'general',
            backend: {},
          },
          () => {
            this.isInitialized = true;
            cb && cb();
          },
        );
    }
  }

  public t = this.instance.t;
}

export default new I18Next();
