import React, { ReactElement } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withMainSimpleLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  AdvancedArticleGeneratorPath,
  AiDetectorPath,
  AiHumanizerPath,
  getAdvancedArticleGeneratorTitle,
  getAiDetectorTitle,
  getAiHumanizerTitle,
  getPaperWriterTitle,
  getParaphraseTextTitle,
  getPlagiarismCheckerTitle,
  getRewriteArticleTitle,
  getSummariseTextTitle,
  getToolsGalleryTitle,
  getToolTitle,
  PaperWriterPath,
  ParaphraseTextPath,
  PlagiarismCheckerPath,
  RewriteArticlePath,
  SummariseTextPath,
  ToolPath,
  ToolsGalleryPath,
  ToolsSidebarKey,
} from '~/modules/tools/constants';
import { useAppSelector } from '~/store/hooks/useAppSelector';

const ToolsGallery = lazyComponentLoader(() => import('./views/ToolsGallery'));
const Tool = lazyComponentLoader(() => import('./views/Tool'));

const AdvancedArticleGenerator = lazyComponentLoader(
  () => import('./views/AdvancedArticleGenerator'),
);

const PlagiarismCheckerV2 = lazyComponentLoader(
  () => import('./views/variant2/PlagiarismCheckerV2'),
);

const AiDetectorV2 = lazyComponentLoader(
  () => import('./views/variant2/AiDetectorV2'),
);

const AiHumanizerV2 = lazyComponentLoader(
  () => import('./views/variant2/AiHumanizerV2'),
);

const ParaphraseTextV2 = lazyComponentLoader(
  () => import('./views/variant2/ParaphraseTextV2'),
);

const ParaphraseTextV3 = lazyComponentLoader(
  () => import('./views/variant2/ParaphraseTextV3'),
);

const SummarizeText = lazyComponentLoader(
  () => import('./views/variant2/SummarizeText'),
);

const RewriteArticle = lazyComponentLoader(
  () => import('./views/variant2/RewriteArticle'),
);

const ToolsGalleryRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(ToolsGallery),
  path: ToolsGalleryPath,
  getTitle: getToolsGalleryTitle,
};

const AdvancedArticleGeneratorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: AdvancedArticleGeneratorPath,
  getTitle: getAdvancedArticleGeneratorTitle,
};

const PaperWriterRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: PaperWriterPath,
  getTitle: getPaperWriterTitle,
};

const PlagiarismCheckerRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainSimpleLayout,
  )(PlagiarismCheckerV2),
  path: PlagiarismCheckerPath,
  getTitle: getPlagiarismCheckerTitle,
};

const AiDetectorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AiDetectorV2),
  path: AiDetectorPath,
  getTitle: getAiDetectorTitle,
};

const AiHumanizerRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainSimpleLayout,
  )(AiHumanizerV2),
  path: AiHumanizerPath,
  getTitle: getAiHumanizerTitle,
};

const ParaphraseTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(() => {
    const { backend_ab_tests } = useAppSelector((state) => state.profile);

    const isParaphraserV3 =
      backend_ab_tests?.['experiment-paraphraser-v3'] === 'v3';

    return isParaphraserV3 ? <ParaphraseTextV3 /> : <ParaphraseTextV2 />;
  }),
  path: ParaphraseTextPath,
  getTitle: getParaphraseTextTitle,
};

const SummarizeTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(SummarizeText),
  path: SummariseTextPath,
  getTitle: getSummariseTextTitle,
};

const RewriteArticleRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(RewriteArticle),
  path: RewriteArticlePath,
  getTitle: getRewriteArticleTitle,
};

export const ToolRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(Tool),
  path: ToolPath,
  getTitle: getToolTitle,
};

router.addPrivateRoutes([
  ToolsGalleryRoute,
  AdvancedArticleGeneratorRoute,
  PaperWriterRoute,
  PlagiarismCheckerRoute,
  AiDetectorRoute,
  AiHumanizerRoute,
  ParaphraseTextRoute,
  SummarizeTextRoute,
  RewriteArticleRoute,
  ToolRoute,
]);

sidebar.addItems({
  a: [
    {
      order: 2,
      key: ToolsSidebarKey,
      path: ToolsGalleryRoute.path,
      getTitle: ToolsGalleryRoute.getTitle,
      Icon: ({ isActive }): ReactElement => {
        return (
          <SidebarIcon
            icon={
              isActive
                ? `material-symbols:library-books`
                : `material-symbols:library-books-outline`
            }
          />
        );
      },
    },
  ],
});
